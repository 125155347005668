//Site

* {
  box-sizing: inherit;
}

body,
html {
  margin: 0;
  // font-family: 'Lato', sans-serif;
  padding: 0;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: initial;
}
$grey: #B8B8B8;
$light-blue: #25BBF3;
$blue: #3596D4;
$light-enterprise: #276379;
$enterprise: #0f3644;
$nav-height: 71px; // $lato: 'Lato', sans-serif;
$inverted: #CECECE;
$gradient-blue: linear-gradient(90deg, #3596d4, #25bbf3);

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mobile {
  display: none;
}

.steps {
  min-height: 100vh;
  // padding-top: 71px;
  background: linear-gradient(90deg, #3596d4, #25bbf3);
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  color: #FFF;
  transition: all 0.2s;
  // font-family: $lato;
  .logo {
    display: flex;
    flex-basis: 331px;
    align-items: flex-end;

    img {
      width: 100%;
      max-width: 331px;
    }

    .hidelogo {
      display: none;
    }
  }

  .left {
    h2 {
      font-size: 2rem;
      font-weight: 300;
      text-align: left;
      margin: 0;
    }

    h1 {
      font-size: 5rem;
      text-align: left;
      margin: 0;
      font-weight: bold;

      span {
        display: block;
      }
    }

    .buttons {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      input,
      select {
        width: 100%;
        padding: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        outline: none;
        border-radius: 5px;
        background: #FFF;
        border: 1px solid #e2e2e2;
        color: #2d2d2d;
        flex: 1;
        border: 0;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
      }

      .btn {
        font-weight: 300;
        color: #FFF;
        text-decoration: none;
        padding: 1rem;
        outline: 0;
        border: 0;
        margin-left: 1rem;

        &:hover {
          background: #FFF;
          color: $blue;
          text-decoration: none;
          transform: translateY(-2px);
          cursor: pointer;
        }
      }

      .create {
        background: $light-blue;
        border-radius: 5px;
      }

      .already-have-account {
        position: relative;
        transition: all 0.2s;

        &:hover {
          padding-left: 3rem;
        }
      }
    }

    p {
      text-align: left;
      max-width: 50%;
    }

    .already {
      a {
        color: #FFF;
      }
    }
  }

  .right {}
}

footer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #FFF;

  span {
    font-size: 0.95em;

    span {
      display: block;
      margin-left: 0;
      padding-top: 0.1rem;
    }

    ul {
      font-size: 0.8rem;
      list-style-type: none;
      font-weight: 400;
      padding: 0;
      margin: 0.2rem 0 0;

      a {
        color: #FFF;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: #FFF;
        }
      }

      li {
        display: inline;
        margin-right: 0.5rem;
      }
    }
  }
}

.step1 {
  .right {
    .channel-boxes {
      width: 30vw;
      height: 30vw;
      position: relative;

      .box {
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 1rem;
        // -webkit-box-shadow: 0 0 20px 2px rgba(0,0,0,0.22);
        // -moz-box-shadow: 0 0 20px 2px rgba(0,0,0,0.22);
        box-shadow: 0 0 0 rgba(255,255,255, 0.4);

        img {
          width: 60%;
          transition: all 0.2s;
        }
      }

      .animate {
        overflow: hidden;
        transform: translatey(0px);
        animation: pulse 2s infinite;
      }

      .big {
        $big: 45%;
        height: $big;
        width: $big;
      }

      .medium {
        $medium: 22%;
        height: $medium;
        width: $medium;
      }

      .small {
        $small: 12%;
        height: $small;
        width: $small;
      }

      .box-1 {
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        animation-delay: 2s;
      }

      .box-2 {
        top: 18%;
        right: 10%;
        z-index: 1;
        animation-delay: 1.5s;

        img {
          width: 90%;
        }
      }

      .box-3 {
        top: 42%;
        right: 13%;
        z-index: 1;
        animation-delay: 1s;

        img {
          width: 90%;
        }
      }

      .box-4 {
        top: 15.2%;
        right: 32.7%;
        z-index: 1;
        animation-delay: 1s;

        img {
          width: 90%;
        }
      }
      @keyframes float {
        0% {
          transform: translatey(0px);
        }

        50% {
          transform: translatey(-10%);
        }

        100% {
          transform: translatey(0px);
        }
      }
      @keyframes floatdown {
        0% {
          transform: translatey(0px);
        }

        50% {
          transform: translatey(1%);
        }

        100% {
          transform: translatey(0px);
        }
      }
      @-webkit-keyframes pulse {
        0% {
          -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
        }

        70% {
          -webkit-box-shadow: 0 0 0 50px rgba(255,255,255, 0);
        }

        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        }
      }
      @keyframes pulse {
        0% {
          -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
          box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
        }

        70% {
          -moz-box-shadow: 0 0 0 50px rgba(255,255,255, 0);
          box-shadow: 0 0 0 50px rgba(255,255,255, 0);
        }

        100% {
          -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
          box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        }
      }
    }
  }
}

.login {
  display: flex;
  flex-direction: column;

  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFF;
    width: 100%;
    z-index: 4;
    max-width: 500px;
    position: relative;
    border-radius: 5px;
    transition: all 0.2s;
    -webkit-box-shadow: 0 0 20px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: 0 0 20px 2px rgba(0,0,0,0.1);
    box-shadow: 0 0 20px 2px rgba(0,0,0,0.1);
    padding: 0 2rem 2rem;
    overflow-y: auto;
    position: relative;
    max-height: calc(90vh - 60px);
    transition: all 0.2s;

    input,
    select {
      width: 100%;
      padding: 1rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      outline: none;
      border-radius: 5px;
      background: #FFF;
      border: 1px solid #e2e2e2;
      color: #34485b;
      flex: 1;
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    }

    label {
      letter-spacing: 0.05em;
      font-size: 0.7rem;
      text-transform: uppercase;
      color: #34485b;
      text-align: left;
      font-weight: normal;
      display: block;
      margin-top: 0.5rem;
      margin-bottom: 0.2rem;
    }

    .input-group {
      width: 90%;
    }

    .logo-wrapper {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      .logo {
        max-width: 300px;
        width: 100%;
        margin: 1rem auto;
      }

      .sub {
        color: #2d2d2d;
        font-size: 1rem;
        margin-top: 0;
        text-align: center;
        font-weight: 300;
      }
    }

    .alert {
      margin-bottom: 10px;
      margin-top: 10px;
      width: 90%;
    }

    .login-btn {
      background: #3596D4;
      color: #FFF;
      display: inline-block;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      font-size: 0.9rem;
      cursor: pointer;
      border: 1px solid #3596D4;
      outline: 0;
      text-decoration: none;
      transition: all 0.15s ease;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      font-size: 0.8rem;
      flex-grow: 1;
      padding: 1rem;
      width: 90%;
      margin-top: 1rem;

      &:hover {
        background: #3596D4;
        text-decoration: none;
        color: #FFF;
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50,50,93,0.1), 0 3px 6px rgba(0,0,0,0.08);
      }
    }
  }
}

.fullpage,
.halfpage {}

.halfpage {
  display: flex;

  .signup {
    width: 30vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    min-width: 500px;

    h2 {
      color: #6D6D6D;
      font-size: 1.875rem;
      font-weight: 300;
      margin-bottom: 0.5rem;
    }

    p {
      color: $light-blue;
      font-weight: 300;
      font-size: 1.2rem;

      span {
        display: block;
      }
    }

    form {
      margin-top: 2rem;
      padding-left: 4rem;
      padding-right: 4rem;
      width: 100%;
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        flex-direction: column;

        label {
          text-align: left;
          font-size: 0.8rem;
          margin-top: 0.5rem;
          margin-bottom: -0.7rem;
          text-transform: uppercase;
          color: #6d6d6d;
        }

        div {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          position: relative;

          i {
            padding: 1rem;
            margin-left: 0.5rem;
            border-radius: 5px;
            color: #6d6d6d;
            cursor: copy;

            &:hover {
              background: #eee;
            }

            &:active {
              background: $light-blue;
              color: #FFF;
            }
          }

          .active {
            &:before {
              content: "Copied";
              padding: 0.2rem;
              transition: 0.2s;
              color: rgba(46,204,113,0.8);
            }
          }
        }
      }

      input {
        display: block;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
        padding: 1rem;
        border-radius: 5px;
        outline: 0;
        border: 1px solid #DCDCDC;
        transition: all 0.2s;
      }

      input[type='submit'] {
        background: $light-blue;
        outline: 0;
        border: 0;
        color: #FFF;
        cursor: pointer;

        &:hover {
          background: $blue;
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .steps {
    .left {
      h1 {
        font-size: 4rem;
      }
    }
  }

  .step1 {
    .right {
      .channel-boxes {
        width: 40vw;
        height: 40vw;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .steps {
    .left {
      h1 {
        font-size: 2.6rem;
      }
    }
  }

  .step1 {
    .right {
      .channel-boxes {
        //
      }
    }
  }

  .mobile {
    display: block;
    background: #FFF;
    color: $light-blue;
    border-radius: 5px;
    font-weight: 300;
    text-decoration: none;
    padding: 1rem;
    outline: 0;
    border: 0;
    margin-top: 2rem;
  }

  .desktop {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .select-container {
    width: 100%;
  }

  .steps {
    .left {
      padding: 2rem;

      h1 {
        text-align: center;
      }

      h2 {
        display: none;
      }

      .buttons {
        margin-top: 2rem;
        flex-direction: column;

        .already-have-account {
          &:hover {
            padding-left: 1rem;
          }
        }

        .create {
          background: #FFF;
          color: $light-blue;
          width: 100%;
          margin: 0 auto;
        }

        .already {
          text-align: center;
          max-width: 100%;
          margin-top: 1rem;
        }
      }
    }
  }

  .step1 {
    .right {
      display: none;
    }
  }

  .halfpage {
    // border: 5px solid red;
  }

  .signup {
    width: 100% !important;
    min-width: 0 !important;

    form {
      max-width: 600px;
    }
  }

  .steps {
    a.goback {
      color: $light-blue;
      background: #FFF;
      padding: 0.5rem;
      border-radius: 5px;
    }
  }
}
@media screen and (max-width: 790px) {
  .step1 {
    background: #FFF;

    .left {
      h1 {
        color: $blue;
      }

      p {
        text-align: center;
        margin-top: 1rem;
        max-width: 100%;
      }

      .buttons {
        input {
          border: 1px solid #e2e2e2;
        }

        .create {
          background: $light-blue;
          color: #FFF;

          &:hover {
            background: $blue;
            color: #FFF;
          }
        }
      }

      .logo {
        img {
          margin: 0 auto;
        }

        .showlogo {
          display: none;
        }

        .hidelogo {
          display: block;
        }
      }
    }

    .already {
      text-align: center;
      color: #424242;

      a {
        color: $light-blue !important;

        &:hover {
          color: $blue;
        }
      }
    }
  }

  .login {
    background: #FFF;

    .login-box {
      box-shadow: none;
      max-height: none;
    }
  }

  footer {
    position: relative;
    color: $blue;
    left: auto;
    bottom: auto;
    color: #424242;
    text-align: center;
  }

  .footer-blue {
    color: $blue !important;

    a {
      color: $light-blue;
    }
  }
}

.docs {
  color: $blue;

  &:hover {
    color: $light-blue;
  }
}

.Fadein {
  animation: fadeIn 0.8s;
  animation-fill-mode: forwards;
  // animation-delay: 0.2s;
  opacity: 0;
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-container {
  position: relative;
  display: inline;
}

.select-container:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}

.select-container:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 45%;
  right: 0.75em;
  border-top: 8px solid black;
  opacity: 0.5;
}

select::-ms-expand {
  display: none;
}
/* SYSTEM */
.company-name {
  text-transform: capitalize;
  color: $blue;
}

.navbar-default {
  background: $blue;
  border-radius: unset !important;
  border: unset !important;

  .navbar-nav {
    li {
      a {
        color: #FFF;

        &:hover {
          color: #e4e4e4;
        }
      }
    }
  }

  .navbar-text {
    color: #FFF;
  }

  .navbar-link {
    color: #FFF;
    font-weight: bold;
    text-transform: capitalize;

    &:hover {
      color: #e4e4e4;
    }
  }
}

.fruugo-steps {
  h2 {
    span {
      font-weight: 300;
      // font-size: 1rem;
    }
  }
}

.feed-options {
  div {
    background: #FFF;
    padding: 1rem;
    margin: 0.5rem;
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: 0 0 20px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: 0 0 20px 2px rgba(0,0,0,0.1);
    box-shadow: 0 0 20px 2px rgba(0,0,0,0.1);
    width: 20%;
    flex-basis: auto;
    max-width: 400px;
    min-width: 300px;
    transition: all 0.2s;

    button {
      background: unset;
      border: unset;
      outline: unset;
    }

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(50,50,93,0.1), 0 3px 6px rgba(0,0,0,0.08);
    }
    &.active {
      border: 1px solid $light-blue;
    }
  }
}
